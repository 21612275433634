
import {
    handleQueryResolve,
    buildMapToField
} from '../utils';

const uniqBy = require('lodash/uniqBy');
const keyBy = require('lodash/keyBy');

export default function(PatientID){
    // classic JS with hoist and scope
    // FIXME break those function to their own modules and call them with this[query]
    const _vm = this;

    return Promise.all([
        queryPatientProfile(PatientID),
        // FIXME might need to fix things
        // queryPharmacyInfo(), // we dont have pharmacy info in kroll
        queryPatientMedication(PatientID),
        queryPatientDoctor(PatientID)
    ]).then(patientRecords => {
        return patientRecords.reduce((patientData, patientRecord) => {
            return {
                ...patientData,
                ...patientRecord
            }
        }, {});
    }, (err) => {
        console.err(err);
    });

    ///
    function queryPatientMedication(PatientID){
        // extract Unqiue DINs of Patient
        return _vm.query(`
            SELECT
                rx.DIN
            FROM
                Pharmacy.dbo.Rx as rx
            WHERE
                rx.PatId = @PatientID AND rx.DIN IS NOT NULL
            GROUP BY rx.DIN
        `, [{
            param: 'PatientID',
            type: 'sql.Int',
            value: PatientID // to support like
        }])
        .then(handleQueryResolve)
        .then((results) => {
            // get records of each PatientDIN
            const DINS_LIST = Object.values(results).reduce((cur, record) => {
                if (record.DIN && !isNaN(record.DIN)) {
                    cur.push(record.DIN);
                }
                return cur;
            }, []);

            // if an empty Array, don't proceed and handle it as a promise error
            if (DINS_LIST.length) {
                return DINS_LIST
            }

            return null;
        })
        .then((listOfPatientDINs) => {
            // console.log(listOfPatientDINs);
            if (listOfPatientDINs && listOfPatientDINs.length) {
                return _vm.query(`
                    SELECT
                        rx.DIN,
                        rx.RxNum as RxNumber,
                        rx.FillDate as RxDate,
                        drug.BrandName,
                        drug.Strength,
                        drug.GenericName,
                        drug.Form,
                        drug.Schedule,
                        drug.Description,
                        rx.DaysSupply as Days,
                        rx.SIG as SIGFULL,
                        rx.DispQty as QtyDispense,
                        rx.RemQty as QtyRemain,
                        0 as Hold,
                        rx.Status,
                        null as Brk,
                        null as Noon,
                        null as Supp,
                        null as Bed,
                        null as IsOTC,
                        rx.Inactive as Inactive
                    FROM
                        Pharmacy.dbo.Rx AS rx
                    INNER JOIN Pharmacy.dbo.Drg as drug ON rx.DrgID = drug.ID
                        WHERE rx.PatID = @PatientID AND rx.DIN IN (${listOfPatientDINs.toString()}) and rx.FillDate > DATEADD(year, -1, GetDate()) AND ( rx.Status <> '2' OR rx.Status <> '3' OR rx.Status is null )
                        ORDER BY rx.FillDate DESC
                    `, 
                    [{
                        param: 'PatientID',
                        type: 'sql.Int',
                        value: PatientID // to support like
                    }]
                );
            }
            // return empty list
            return {
                medications: []
            };
        },
        // handle empty DIN LIST 
        () => {
            return {
                medications: []
            };
        })
        .then(handleQueryResolve)
        .then((medicationResults) => {
            let listOfNonRepeatedMeds = uniqBy(medicationResults, 'DIN').map((record, index) => {
                return {
                    // [`ID`]: key,
                    [`DIN`]: record['DIN'],
                    [`RxNumber`]: record['RxNumber'],
                    [`RxDate`]: record['RxDate'],
                    [`QtyDispense`]: record['QtyDispense'],
                    [`QtyRemain`]: record['QtyRemain'],
                    [`Days`]: record['Days'],
                    [`RX?`]: record['Schedule'] == '1' ? 'Yes' : '',
                    [`NHP?`]: '',
                    [`GenericName`]: record['GenericName'],
                    [`OTC?`]: record['IsOTC'] == '1' ? 'Yes' : '',
                    [`onHold`]: record['Hold'] == '1' ? 'Yes' : '',
                    [`MED`]: record['BrandName'],
                    [`MED STR`]: record['Strength'],
                    [`FORM`]: record['Form'],
                    [`SIG`]: record['SIGFULL'],
                    [`INDICATION`]: '',
                    [`MED AM QTY`]: record['Brk'],
                    [`MED LUNCH QTY`]: record['Noon'],
                    [`MED DINNER QTY`]: record['Supp'],
                    [`MED BEDTIME QTY`]: record['Bed'],
                    [`MED OTHER QTY`]: '',
                    [`ADHERENCE YES`]: '',
                    [`ADHERENCE NO`]: '',
                    [`PATIENT COMMENT`]: '',
                    [`MED PHARMACIST NOTES`]: '',
                    [`COMMENTS FOR MEDSCHECK RECORD`]: record['Description'] ? [record['Description'].toUpperCase()] : ''
                }
            });

            return {
                medications: uniqBy(listOfNonRepeatedMeds, med => [med["GenericName"], med["MED STR"]].join())
            };
        }).then( medicationsInst => {
         
            /*
                ,[Id]
                ,[Language]
                ,[QtyLow]
                ,[QtyHigh]
                ,[FrequencyNumerator]
                ,[FrequencyDenominator1]
                ,[FrequencyDenominator2]
                ,[FrequencyDenominatorUnits]
                ,[Duration]
                ,[IsCondition]
                ,[Condition]
                ,[IsSupplementalInstruction]
                ,[SupplementalInstruction]
                ,[IsRouteOfAdmin]
                ,[IsMaxDosage]
                ,[MaxDoseQty]
                ,[MaxDoseQtyUnit]
                ,[MaxDoseRange]
                ,[MaxDoseRangeUnit]
            */
            return _vm.query(`
                SELECT 
                    [Token],
                    [Text]
                FROM Pharmacy.dbo.Sig
                WHERE Language = 'E'
            `)
            .then(handleQueryResolve)
            .then( tokens => {
                // to translate short sig to full sig
                let tokenMap = keyBy(tokens, o => o.Token);
                medicationsInst.medications = medicationsInst.medications.map(medication => {
                    let sig = medication['SIG'];
                    // // logic to translate tokens
                    // "SIGFULL": "T 1 GELCAP PO BID"
                    medication['SIG'] = sig.split(' ').map((token) => {
                        let tokenInst = tokenMap[token];
                        if (tokenInst) {
                            return tokenInst.Text
                        }
                        return token;
                    }).join(' ');
                    // // end of full sig
                    return medication;
                });

                return medicationsInst;
            });
        });
    }


    // TODO OCP Pharmacist's # is under "USERS"
    function queryPharmacyInfo(){
        // FIXME you cant query Fillware
        return _vm.query(`
            SELECT DISTINCT
                sParameterName,
                sParameterValue
            FROM
                Fillware.dbo.SystemParameters
            WHERE
                (
                    sParameterName = 'PharmacyName'
                    OR sParameterName = 'Address'
                    OR sParameterName = 'City'
                    OR sParameterName = 'Province'
                    OR sParameterName = 'Postal'
                    OR sParameterName = 'Fax'
                    OR sParameterName = 'Phone'
                )
            AND
                (
                    sParameterValue IS NOT NULL OR sParameterValue != ''
                )
        `, [])
        .then(handleQueryResolve)
        .then((recordset) => {

            const MapValuesTo = {
                PharmacyName: 'PharmacyName',
                Address: 'PharmacyAddress',
                City: 'PharmacyCity',
                Province: 'PharmacyProvince',
                Postal: 'PharmacyPostal',
                Fax: 'PharmacyFax',
                Phone: 'PharmacyPhone'
            };

            const whiteList = Object.keys(MapValuesTo);

            let pharmacyInfo = recordset.reduce((cur, row) => {

                if (whiteList.indexOf(row['sParameterName']) == -1 || !row['sParameterValue']) {
                    return cur;
                }

                return {
                    ...cur,
                    [MapValuesTo[row['sParameterName']]]: row['sParameterValue']
                }
            }, {});

            return buildMapToField(pharmacyInfo);
        });
    }

    function queryPatientProfile(PatientID) {
        return _vm.query(`
            SELECT
                patient.ID as PatientID,
                patient.FirstName,
                patient.LastName,
                null as FamilyDoc,
                (SELECT ClientID FROM Pharmacy.dbo.PatPln WHERE PatID = @PatientID AND SubPlanID = 185) as OHIP,
                patient.Address1 as Address,
                patient.City,
                patient.Prov as Province,
                patient.Postal,
                patient.EMail,
                (SELECT TOP(1) Phone FROM Pharmacy.dbo.PatPhone WHERE PatID = @PatientID) as HomePhone,
                null as Allergies,
                patient.Birthday as DOB,
                patient.Sex as Gender,
                null as LastVisit,
                null as MedsCheckReminder
            FROM
                Pharmacy.dbo.Pat as patient
            WHERE
                patient.ID = @PatientID
            `, [{
                param: 'PatientID',
                type: 'sql.Int',
                value: PatientID
            },{
                param: 'PatientID',
                type: 'sql.Int',
                value: PatientID
            }, {
                param: 'PatientID',
                type: 'sql.Int',
                value: PatientID // to support like
            }]
        )
        .then(handleQueryResolve, (e) => {
            console.log(e)
        })
        .then((recordset) => {
            return buildMapToField(recordset[0]);
        });
    }

    // Primary Provider is the top doctor
    function queryPatientDoctor(PatientID){
        return _vm.query(`
            SELECT TOP(2)
                COUNT(Rx.DocID) AS Expr1,
                Doc.LastName,
                Doc.FirstName,
                Doc.Licence1 AS CPSO,
                DocPhone.Phone,
                DocPhone.Type
            FROM
                Pharmacy.dbo.Rx
            INNER JOIN Pharmacy.dbo.Doc ON Rx.DocID = Doc.ID
            INNER JOIN Pharmacy.dbo.DocPhone ON Doc.ID = DocPhone.DocID
            WHERE
                Rx.PatID = @PatientID
            GROUP BY
                Doc.LastName,
                Doc.FirstName,
                Doc.Licence1,
                DocPhone.Phone,
                DocPhone.Type
            ORDER BY Expr1 DESC
        `, [{
            param: 'PatientID',
            type: 'sql.Int',
            value: PatientID
        }])
        .then(handleQueryResolve)
        .then((recordset) => {
            if (recordset.length) {
                let doctorPhoneRecord = recordset[0];
                let doctorFaxRecord = '';
                // check for fax since its type 2
                if (
                    recordset.length > 1 &&
                    recordset[1].FirstName &&
                    recordset[1].LastName
                ) {
                    doctorFaxRecord = recordset[1];
                }

                // build record object
                let record = {
                    DoctorFirstName: doctorPhoneRecord.FirstName,
                    DoctorLastName: doctorPhoneRecord.LastName,
                    CPSO:doctorPhoneRecord.CPSO,
                    DoctorEmail: '',
                    DoctorPhone: doctorPhoneRecord.Phone,
                    DoctorFax: doctorFaxRecord.Phone
                };

                return buildMapToField(record);
            }

            return {};
        }, (err) => {
            console.error(err);
        });
    }
}